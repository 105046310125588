import React from 'react';
import PropTypes from 'prop-types';

// Internal
import Photo from './photo';

const PhotoRow = ( props ) => {
	return (
		<div
			style={ {
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
			} }
		>
			{
				props.photos.map( photo => {
                    console.log("1");
                    console.log(photo)
					return (
						<Photo
                            thumb={photo.node.childImageSharp.thumb}
                            full={photo.node.childImageSharp.full}
                            src = {photo.node.publicURL}
						/>
					)
				} )
			}
		</div>
	)
}

PhotoRow.propTypes = {
	photos: PropTypes.array,
}

export default PhotoRow;