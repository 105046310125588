import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
// import { FaBullseye } from 'react-icons/fa';

import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoRow from "../components/row"

const seoTitle = "School Gallery"

const SchoolGalleryPage = ({data}) => {
    const photoArray = [];
    let photoRow = [];
    let count = 0;

     return <Layout>
        <SEO title={seoTitle}/>
        <div className="container">            
            <div className="row article-banner">
                <div className="col">
                    <Img fluid={data.bannerql.childImageSharp.fluid} />
                </div>
            </div>
            {/* <div className="row article-heading">
                <div className="col">
                    <h3><span className="label label-primary">{data.markupql.edges[0].node.frontmatter.heading}</span></h3>
                </div>
            </div>             */}
            <div className="row article-text">
                <div className="col">
                    {
                        data.galleryql.edges.map(photo => {
                            if ( photoRow.length === 3 ) {
                                photoRow = [];
                            }    
                            photoRow.push( photo );
                            count++;    
                            if ( photoRow.length === 3 ) {
                                return <PhotoRow photos={ photoRow } key={ count } />
                            } else if ( photoArray.length - count === 0 ) {
                                return <PhotoRow photos={ photoRow } key={ count } />	
                            }
                        })
                    }
                </div>
            </div>
        </div>
    </Layout>
}

export default SchoolGalleryPage

export const query1 = graphql `
    query {
    bannerql: file(relativePath: {eq: "banners/photo-gallery-1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    galleryql: allFile(filter: {extension: {eq: "jpg"},, relativeDirectory: {eq: "photo-gallery-1"}}){
        edges{
          node{
            id,
            publicURL
            childImageSharp{
                thumb: fluid(maxWidth: 270, maxHeight: 270) {
                    ...GatsbyImageSharpFluid
                }
                full: fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
}
`