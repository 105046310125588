import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Link from 'gatsby-link';

const Photo = ( props ) => {
    console.log("here")
    console.log(props.sizes)
	return (
		<div
			style={ {
				width: 'calc( 100% / 3 )',
				padding: '0.2em',
			} }
		>
			<Link to={ props.src } >
				<Img
					fluid={ props.thumb }
					style={ {
						width: 'auto',
						height: 'auto',
					} }
				/>
                
			</Link>
		</div>
	)
}

Photo.propTypes = {
	src: PropTypes.string,
	sizes: PropTypes.object,
    link: PropTypes.string,
    thumb: PropTypes.object,
    full: PropTypes.object
}

export default Photo;